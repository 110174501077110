var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("v-container", [
        _c("h1", [_vm._v("Risultati ricerca")]),
        _c(
          "div",
          [
            _c(
              "v-row",
              _vm._l(_vm.postList, function(post) {
                return _c(
                  "v-col",
                  {
                    key: post.id,
                    attrs: { cols: "12", sm: "6", md: "4", lg: "3" }
                  },
                  [_c("search-card", { attrs: { post: post } })],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }