var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "fill-height my-2 rounded-md d-flex flex-column post-card",
      attrs: {
        outlined: "",
        to: {
          name: _vm.routeName,
          params: { postName: _vm.post.slug }
        }
      }
    },
    [
      _c("v-img", {
        attrs: {
          height: "250",
          "max-height": "250",
          src: _vm.post.featured_image_url,
          alt: `Immagine: ${_vm.post.title}`
        }
      }),
      _vm.post.type === "post"
        ? _c("v-card-subtitle", { staticClass: "py-0" }, [
            _c("time", [
              _vm._v(_vm._s(_vm.$dayjs(_vm.post.date).format("LL")) + " ")
            ])
          ])
        : _vm._e(),
      _c(
        "v-card-title",
        {
          staticClass:
            "card-description pa-3 body-1 font-weight-bold d-flex align-start"
        },
        [_vm._v(_vm._s(_vm.post.title))]
      ),
      _c(
        "v-card-text",
        { staticClass: "post-content card-description-secondary" },
        [_vm._v(" " + _vm._s(_vm.post.exerpt) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }